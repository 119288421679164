html {
  scroll-snap-type: y mandatory;
  scroll-behavior: smooth;
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "DM Sans", sans-serif;
}

body {
  background-color: #0c0c1d;
  color: lightgray;
}

a {
  text-decoration: none;
  color: inherit;
}

section {
  height: 100vh;
  width: 100vw;
  scroll-snap-align: center;
  overflow: hidden;
}
