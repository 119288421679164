.donnees-et-analyses {
    background-color: white;
    color: #111132;
    padding: 80px 0;
  
    h2 {
      font-size: 36px;
      text-align: center;
      margin-bottom: 40px;
    }
  
    .donnees,
    .analyses {
      margin-bottom: 30px;
  
      h3 {
        font-size: 24px;
        margin-bottom: 15px;
      }
  
      p {
        font-size: 18px;
      }
    }
  
    &.animate {
      .donnees,
      .analyses {
        transform: translateY(0);
      }
    }
  }
  