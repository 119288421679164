@import "../../App.scss";

.navbar {
  height: 100px;

  .wrapper {
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;

    h1 {
      margin-left: 20px;
    }

    .logo {
      display: flex;
      gap: 20px;

      img {
        width: 18px;
        height: 18px;
      }
    }
  }
}