.avantages {
    background-color: #111132;
    color: white;
    padding: 80px 80px;
  
    h2 {
      font-size: 36px;
      text-align: center;
      margin-bottom: 40px;
    }
  
    .avantage {
      margin-bottom: 30px;
  
      h3 {
        font-size: 24px;
        margin-bottom: 15px;
      }
  
      p {
        font-size: 18px;
      }
      ul {
        list-style-type: none;
        padding-left: 20px;
        margin-top: 10px;
  
        li {
          padding-top: 10px;
          font-size: 18px;
          cursor: pointer;
        }
      }
    }
  
    &.animate {
      .avantage {
        transform: translateY(0);
      }
    }
  }
  