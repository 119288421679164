.introduction {
    color: lightgray;
    padding: 20px;
    text-align: center;
    background-color: #0c0c1d;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  
    img {
      max-width: 30%;
      height: 40%;
      margin-bottom: 20px;
    }
  
    h1, h2 {
      margin: 10px 0;
    }
  
    h1 {
      font-size: 24px;
      color: #4da6ff;
    }
  
    h2 {
      font-size: 20px;
      color: #a4c639;
    }
  
    p {
      font-size: 16px;
      line-height: 1.5;
      max-width: 600px;
    }
  }