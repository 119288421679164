.improvements {
    padding: 80px 0;
    background-color: #f7f7f7; /* Couleur de fond de la section, à personnaliser */
  
    h2 {
      font-size: 36px;
      color: #111132; /* Couleur du titre, à personnaliser */
      text-align: center;
      margin-bottom: 40px;
    }
  
    .improvement {
      text-align: center;
      margin-bottom: 30px;
  
      h3 {
        font-size: 24px;
        color: #111132; /* Couleur du titre de l'amélioration, à personnaliser */
      }
  
      ul {
        list-style-type: none;
        padding-left: 20px;
        margin-top: 10px;
  
        li {
          padding-top: 10px;
          font-size: 18px;
          color: #666; /* Couleur du texte, à personnaliser */
          cursor: pointer;
        }
      }
      .comment {
        margin-top: 10px;
        font-style: italic;
        color:#111132;
        // Ajoutez d'autres styles pour les commentaires ici
      }
    }
  }
  