.solutions {
    padding: 60px 0;
    background-color: #fff; /* Couleur de fond de la section, à personnaliser */
  
    h2 {
      font-size: 36px;
      color: #111132; /* Couleur du titre, à personnaliser */
      text-align: center;
      margin-bottom: 40px;
    }
  
    .problem {
      text-align: center;
      margin-bottom: 30px;
  
      h3 {
        font-size: 24px;
        color: #111132; /* Couleur du titre de problème, à personnaliser */
      }
  
      p {
        font-size: 18px;
        color: #666; /* Couleur du texte, à personnaliser */
      }
    }
  
    .solution-image {
      text-align: center;
      margin-top: 30px;
  
      img {
          width: 50%;
          height: auto; // Modifié pour remplir la hauteur du conteneur
          object-fit: cover; // Assure que l'image couvre entièrement la zone, mais peut être coupée
          object-position: bottom; // Alignement de l'image sur le bas
      }
  }
  }