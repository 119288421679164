@import "../../App.scss";

.home {
  height: calc(100vh - 100px);
  overflow: hidden;
  background: linear-gradient(180deg, #0c0c1d, #111132);
  position: relative;

  .wrapper {
    max-width: 1366px;
    height: 100%;
    margin: auto;

    .textContainer {
      width: 50%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 40px;


      h2 {
        font-size: 30px;
        color: rebeccapurple;
        letter-spacing: 10px;
      }

      h1 {
        font-size: 88px;

      }

      .buttons {
        display: flex;
        gap: 20px;
        z-index: 10;

        a {
          padding: 20px;
          border: 1px solid white;
          border-radius: 10px;
          background-color: transparent;
          color: white;
          cursor: pointer;
          font-weight: 300;
          text-decoration: none;
          display: inline-block;

          &:hover {
            background-color: white;
            color: #111132;
          }
        }
      }


      img {
        width: 50px;
      }
    }
  }

  .imageContainer {
    height: 60%;
    width: 50%;
    position: absolute;
    top: 0;
    right: 0;


    img {
      width: 100%;
      height: 100%;

    }
  }

  .slidingTextContainer {
    position: absolute;
    font-size: 50vh;
    bottom: -120px;
    white-space: nowrap;
    color: #ffffff09;
    width: 50%;
    font-weight: bold;
  }
}