.problematiques {
  padding: 80px 0;
  background-color: #fff;

  h2 {
      font-size: 36px;
      color: #111132;
      text-align: center;
      margin-bottom: 40px;
  }

  .problems-container {
      display: flex;
      align-items: flex-start;
      overflow: hidden;

      .problems {
          flex-basis: 50%;
          
          color: #111132; // Initialement, occupe 50% de la largeur
          cursor: pointer;

          h3{
            margin-top: 20px;
            margin-bottom: 10px;
          }
          .problem {
            // Styles par défaut pour les problèmes
            background-color: #fff;
            color: #111132;

            &.active {
                // Styles pour le problème actif
                background-color: #111132;
                color: #fff;
            }
          }
      }

      .solution {
          flex-basis: 50%; // Occupe 50% de la largeur
          opacity: 0; // Initialement cachée
          overflow: hidden;
          color: #111132; 
          padding-left: 30px;
          padding-right: 20px;
          h4{
            margin-bottom: 10px;
          }
          li{
            margin-bottom: 10px;
          }
      }
  }
}
