.business {
    background-color: #111132;
    color: white;
    padding: 80px 0;
  
    h2 {
      font-size: 36px;
      text-align: center;
      margin-bottom: 40px;
    }
  
    .business-content {
      display: flex;
      justify-content: space-around;
      flex-wrap: wrap;
    }
  
    .business-item {
      flex-basis: calc(50% - 20px);
      margin: 0 10px;
      padding: 20px;
      border: 1px solid #394162;
      border-radius: 10px;
      margin-bottom: 20px;
      background-color: #1e1e3d;
      transition: transform 0.3s ease-in-out;
  
      &:hover {
        transform: translateY(-5px);
      }
  
      h3 {
        font-size: 24px;
        margin-bottom: 15px;
      }
  
      ul {
        list-style-type: disc;
        margin-left: 20px;
        li {
          margin-bottom: 10px;
        }
      }
    }
  
    &.animate {
      .business-item {
        transform: translateY(0);
      }
    }
  }
  